import {includeSvgResource, wrapInsideElement} from "./utils";
import {Carousel} from "./carousel";
import {Observe} from "./observe";
import {Window} from "./window";


if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty('remove')) {
        return;
        }
        Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
            if (this.parentNode === null) {
            return;
            }
            this.parentNode.removeChild(this);
        }
        });
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
  

class App {
    /**
     * Body element
     *
     * @type {jQuery|HTMLElement}
     */
    body = $('body');

    /**
     * Windows service
     *
     * @type {Window}
     */
    window = new Window();

    /**
     * Compare button timeout
     */
    tooltip_show_timeout;
    tooltip_hide_timeout;


    constructor() {
        this.initCarousel();
        this.initCompareButtons();
        this.initCombobox();
        this.initIntersectionObserver();
        this.initPopovers();
        this.initSvgResource();
        this.initSwiperGallery();
        this.initTooltips();

        this.handleHeaderToggleButtons();
    }

    initCarousel() {
        $('[data-toggle="owl-carousel"]').each((key, item) => {
            new Carousel(item);
        });
    }

    initCombobox() {
        $('[data-toggle="combobox"]').each((key, item) => {
            new Combobox(item);
        });
    }

    initPopovers() {
        //$('[data-toggle="popover"]').popover();
    }

    initSvgResource() {
        if (typeof TB.svgResource.path !== "undefined") {
            includeSvgResource(TB.svgResource.path, TB.svgResource.id, TB.svgResource.version);
        }
    }

    initSwiperGallery() {
        $('[data-toggle="gallery"]').each((key, item) => {
            new SwiperGallery(item);
        });
    }

    /**
     * Trigger tooltips
     */
    initTooltips() {
        this.body.on('mouseenter', '[data-toggle="tooltip"]', function () {
            //$(this).tooltip('show');
        });
    }

    /**
    * Compare button
    */
    initCompareButtons() {
        const self = this;

        document.body.addEventListener('click', function(e) {
            let btn_compare = e.target;

            if (!e.target.classList.contains('compare-button')) {
                return;
            }

            e.preventDefault();

            let btn_results = '',
                offer_orig  = btn_compare.closest('.card-offer'),
                index       = [].indexOf.call(offer_orig.closest('.row').children, offer_orig.closest('.col')),
                offer_anim  = offer_orig.cloneNode(true),
                scroll_top  = window.scrollY,
                remove      = false;

            offer_anim.className += ' offer-' + index + ' inactive'

            if (window.outerWidth > 992) {
                if (document.querySelector('#listing-header').classList.contains('sticky-active')) {
                    btn_results = document.querySelector('#listing-header').querySelectorAll('.compare-button-results.btn');
                    btn_results = btn_results[btn_results.length - 1];
                } else {
                    btn_results = document.querySelector('#top_bar').querySelector('.compare-button-results.btn');
                }
            } else {
                if (document.querySelector('#header').classList.contains('sticky-active')) {
                    btn_results = document.querySelector('#listing-header').querySelector('.compare-button-results.badge');
                } else {
                    btn_results = document.querySelector('#header').querySelector('.compare-button-results.badge');
                }
            }

            let compare_items = parseInt(btn_results.querySelector('.comparison-button-number').textContent);

            btn_compare.classList.add('inactive');

            if (btn_compare.classList.contains('compare-button-selected')) {
                remove = true;

                compare_items--;

                btn_compare.querySelector('.tb__icon').remove();
                btn_compare.insertAdjacentHTML('afterbegin', '<span class="tb__icon mr-1"><svg width="20" height="16"><use xlink:href="#ico--compare"></use></svg></span>')
                btn_compare.classList.remove('compare-button-selected');
            } else {
                compare_items++;

                btn_compare.querySelector('.tb__icon').remove();
                btn_compare.insertAdjacentHTML('afterbegin', '<span class="tb__icon mr-1"><svg width="20" height="14"><use xlink:href="#ico--cross"></use></svg></span>');
                btn_compare.classList.add('compare-button-selected');
            }
    
            btn_results.style.animation = '';

            if (!remove) {
                document.body.appendChild(offer_anim);

                offer_anim.style.opacity = 1;
                offer_anim.style.transition = 'transform 0.5s ease-in-out, opacity 0.3s ease-in-out';
                offer_anim.style.transitionDelay = '0.3s, 0.6s';
                offer_anim.style.transformOrigin = '0 0';
                offer_anim.style.width = offer_orig.offsetWidth + 'px';
                offer_anim.style.position = 'fixed';
                offer_anim.style.zIndex = 10000;
                offer_anim.style.top = (offer_orig.getBoundingClientRect().top - document.body.scrollTop) + 'px';
                offer_anim.style.left =  offer_orig.getBoundingClientRect().left + 'px';

                offer_anim.style.opacity = 0;
                offer_anim.style.transform = 'translateX(' + (btn_results.getBoundingClientRect().left - offer_orig.getBoundingClientRect().left) + 'px) translateY(' + (btn_results.getBoundingClientRect().top - offer_orig.getBoundingClientRect().top) + 'px) scale(0.2)';
            }

            wrapInsideElement('.comparison-button-number', '<span></span>');

            for (let el of document.querySelectorAll('.comparison-button-number')) {
                el.insertAdjacentHTML('afterbegin', '<span>' + compare_items + '</span>');
            }

            setTimeout(function () {
                for (let el of document.querySelectorAll('.comparison-button-number > span:first-child')) {
                    el.style.marginTop = '0px';
                }
            }, 300);

            setTimeout(function () {
                for (let el of document.querySelectorAll('.comparison-button-number > span:last-child')) {
                    el.remove();
                }

                btn_compare.classList.remove('inactive');
            }, 500);

            if (!remove) {
                self.tooltip_show_timeout = setTimeout(function () {
                    btn_results.style.animation = 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both, pulse 0.32s cubic-bezier(.36,.07,.19,.97) both';
                    btn_results.parentElement.style.position = 'relative';

                    btn_results.parentElement.insertAdjacentHTML('afterbegin', '<div class="tooltip fade show bs-tooltip-left" style="position: absolute; will-change: transform; right: calc(100% + 10px);"><div class="arrow" style="top: 8px;"></div><div class="tooltip-inner" style="white-space: nowrap;">Presiona aquí para comparar</div></div></div>')
                }, 600);

                setTimeout(function () {
                    for (let el of document.querySelectorAll('body > .offer-' + index)) {
                        el.remove();
                    }
                }, 1200);

                self.tooltip_hide_timeout = setTimeout(function () {
                    btn_results.parentElement.querySelector('.tooltip').remove();
                }, 3000);
            }

            return false;
        });
    }

    /**
     * Intersection Observer
     */
    initIntersectionObserver() {
        $('[data-toggle="observe"]').each((key, item) => {
            new Observe(item);
        });
    }


    /**
     * Header mobile nav button
     */
    handleHeaderToggleButtons() {
        this.body
            .on('click', '.btnNavToggle', (e) => {
                $('#headerCollapsable').toggleClass('show');
                $('#header').find('.navbar-toggler').toggleClass('collapsed');

                if ($('#headerCollapsable').is('.show')) {
                    $('html').addClass('mobileMenuShow');
                } else {
                    $('html').removeClass('mobileMenuShow');
                }
            });
        this.body
            .on('click', (e) => {
                if ($('#headerCollapsable').is('.show')) {
                    if ($(e.target).is('#header')) {
                        $('.btnNavToggle').trigger('click');
                    }
                }
            });
}

}

$(function () {
    new App();
});