import {convertToObject} from "./utils";

export class Carousel {
  carousel = null;

  defaults = {
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      650: {
        items: 2
      },
      1000: {
        items: 3
      }
    },
    autoHeight: false,
    checkVisible: false
  };

  constructor(element) {
    this.carousel = $(element);

    const config = this.config();

    if (this.carousel.is('.row') && !config.margin) {
      config.margin = 42;
    }

    this.carousel.owlCarousel(config);

    this.carousel.trigger('refresh.owl.carousel');
  }

  config() {
    const data = this.carousel.data();

    Object.keys(data).forEach(function (key) {
      if (data[key].toString().indexOf('{') === 0) {
        data[key] = convertToObject(data[key]);
      }
    });

    return $.extend({}, this.defaults, data);
  }
}